export function checkRoleAndHospital(state, role) {
  return state.currentUser?.role === role && !state.currentUser?.hospital_id;
}

export function isCompanyAdmin(state) {
  return (
    state.currentUser?.company?.user_id === state.currentUser?.id &&
    state.currentUser?.role === "company_admin"
  );
}

export function isMedicalAdmin(state) {
  return (
    state.currentUser?.role === "med_admin" && state.currentUser?.hospital_id
  );
}
