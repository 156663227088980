import { createApp } from "vue";
import { createPinia } from "pinia";
import Maska from "maska";
import VueCookies from "vue-cookies";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import cyrillicOnlyDirective from "./directives/cyrillicOnly.js";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
const app = createApp(App);

const options = {
  position: "top-right",
  timeout: 2984,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

Sentry.init({
  app,
  dsn: "https://75904a6824c5d8aef6c5e9e74e275a2f@o4506128003825664.ingest.sentry.io/4506145749336064",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "spasibovracham.tatar", /^https:\/\/spasibovracham\.tatar\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(router);
app.use(Maska);
app.use(Toast, options);
app.directive("cyrillic-only", cyrillicOnlyDirective);
app.use(VueCookies, { expires: "365d" });
app.mount("#app");
