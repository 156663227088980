import axios from 'axios';

const api = axios.create({
  // baseURL: "http://127.0.0.1:3000/api/",
  baseURL: "https://spasibovracham.tatar/api",
  timeout: 5000,
  mode: 'cors',
  credentials: 'include',
});

export default api;