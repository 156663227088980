import { useToast } from "vue-toastification";

const cyrillicOnlyDirective = {
  beforeMount(el) {
    const toast = useToast();

    let showedToast = false;

    el.addEventListener("input", (evt) => {
      const cyrillicPattern = /^[\u0400-\u04FF\s]*$/;

      if (!cyrillicPattern.test(evt.target.value)) {
        evt.target.value = evt.target.value.replace(/[^\u0400-\u04FF\s]/g, "");

        if (!showedToast) {
          toast.error("Только кириллица");
          showedToast = true;
        }
        evt.target.dispatchEvent(new Event("input"));
      } else {
        showedToast = false;
      }
    });
  },
};

export default cyrillicOnlyDirective;
