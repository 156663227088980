import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "/src/stores/user-store";
import { useToast } from "vue-toastification";

const toast = useToast();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../pages/IndexPage.vue"),
      meta: { requiresAuth: false },
    },
    {
      path: "/org",
      name: "orgDashboardPage",
      component: () => import("../pages/org/DashboardPage.vue"),
      meta: { requiresAuth: true, userType: ["company_admin"] },
    },
    {
      path: "/org/index",
      name: "orgIndexPage",
      component: () => import("../pages/org/IndexPage.vue"),
      meta: { requiresAuth: false, userType: ["company_admin"] },
    },
    {
      path: "/med",
      name: "medDashboardPage",
      component: () => import("../pages/med/DashboardPage.vue"),
      meta: {
        requiresAuth: true,
        userType: [
          "med_admin",
          "city_med_admin",
          "resp_med_admin",
          "all_med_admin",
        ],
      },
    },
    {
      path: "/med/auth",
      name: "medAuthPage",
      component: () => import("../pages/med/AuthPage.vue"),
      meta: {
        requiresAuth: false,
        userType: [
          "med_admin",
          "city_med_admin",
          "resp_med_admin",
          "all_med_admin",
        ],
      },
    },
    {
      path: "/med/users",
      name: "medUsersPage",
      component: () => import("../pages/med/MedUsersPage.vue"),
      meta: {
        requiresAuth: true,
        userType: [
          "med_admin",
          "city_med_admin",
          "resp_med_admin",
          "all_med_admin",
          "company_admin",
        ],
      },
    },
    {
      path: "/registration",
      name: "doctorRegistration",
      component: () => import("../pages/doctor/RegistrationPage.vue"),
      meta: { requiresAuth: false },
    },
  ],
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();

  let res = true;

  if (!userStore.currentUser) {
    res = await userStore.me();
  }

  if (to.meta.requiresAuth && res && userStore.currentUser) {
    if (!to.meta.userType.includes(userStore.currentUser.role)) {
      toast.error("У вас недостаточно прав");

      return {
        path: "/",
      };
    }
  }

  if (
    (to.fullPath === "/" || to.fullPath === "/med/auth") &&
    res &&
    (userStore.isMedicalAdmin ||
      userStore.isCityOnlyMedicalAdmin ||
      userStore.isRespOnlyMedicalAdmin ||
      userStore.isAllMedicalAdmin)
  ) {
    return {
      path: "/med/users",
    };
  }

  if (
    (to.fullPath === "/" || to.fullPath === "/med/auth") &&
    res &&
    userStore.isSuperMedicalAdmin
  ) {
    return {
      path: "/med",
    };
  }

  if (
    to.fullPath === "/med" &&
    res &&
    (userStore.isMedicalAdmin ||
      userStore.isCityOnlyMedicalAdmin ||
      userStore.isRespOnlyMedicalAdmin ||
      userStore.isAllMedicalAdmin)
  ) {
    return {
      path: "/med/users",
    };
  }

  if (
    (to.fullPath === "/" || to.fullPath === "/org/index") &&
    res &&
    userStore.currentUser.role === "company_admin"
  ) {
    return {
      path: "/org",
    };
  }

  if (to.meta.requiresAuth && !res) {
    if (to.meta.userType === "company_admin") {
      toast.error("Вам необходимо авторизоваться");

      return {
        path: "/org/index",
      };
    } else if (to.meta.userType === "med_admin") {
      toast.error("Вам необходимо авторизоваться");

      return {
        path: "/med/auth",
      };
    } else if (to.meta.userType === "city_med_admin") {
      toast.error("Вам необходимо авторизоваться");

      return {
        path: "/med/auth",
      };
    } else {
      toast.error("Вам необходимо авторизоваться");

      return {
        path: "/",
      };
    }
  }
});

export default router;
