import { defineStore } from "pinia";
import api from "../plugins/axios.js";
import { useToast } from "vue-toastification";
import {
  checkRoleAndHospital,
  isCompanyAdmin,
  isMedicalAdmin,
} from "../utils/roleHelpers.js";

const toast = useToast();

export const useUserStore = defineStore("user", {
  state: () => ({
    currentUser: null,
    passwordDialogOpen: false,
  }),

  getters: {
    isCompanyAdmin, // админ коммерческой компании
    isMedicalAdmin, // админ конкретного мед учреждения
    isCityOnlyMedicalAdmin: (state) =>
      checkRoleAndHospital(state, "city_med_admin"), // админ всех городских мед учреждений
    isRespOnlyMedicalAdmin: (state) =>
      checkRoleAndHospital(state, "resp_med_admin"), // админ всех республиканских мед учреждений
    isAllMedicalAdmin: (state) => checkRoleAndHospital(state, "all_med_admin"), // админ всех мед учреждений
    isSuperMedicalAdmin: (state) => checkRoleAndHospital(state, "med_admin"), // админ мед учерждений и промо-акций

    menuItems() {
      if (this.isCompanyAdmin) {
        return [
          {
            title: "Мои акции",
            link: "/org",
          },
          {
            title: "Пользователи",
            link: "/med/users",
          },
        ];
      }

      if (this.isSuperMedicalAdmin) {
        return [
          {
            title: "Управление акциями",
            link: "/med",
          },
          {
            title: "Пользователи",
            link: "/med/users",
          },
        ];
      }

      if (
        this.isMedicalAdmin ||
        this.isCityOnlyMedicalAdmin ||
        this.isRespOnlyMedicalAdmin ||
        this.isAllMedicalAdmin
      ) {
        return [
          {
            title: "Пользователи",
            link: "/med/users",
          },
        ];
      }

      return [];
    },
  },

  actions: {
    async me() {
      if (localStorage.getItem("auth-token")) {
        api.defaults.headers.common["Authorization"] = null;
        api.defaults.headers.common["Authorization"] =
          localStorage.getItem("auth-token");
      }

      return new Promise((resolve) => {
        api
          .get("/me", { withCredentials: true })
          .then((resp) => {
            this.currentUser = JSON.parse(
              JSON.stringify(resp.data.data.attributes)
            );
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      });
    },

    async login(payload) {
      localStorage.removeItem("auth_token");
      api.defaults.headers.common["Authorization"] = "";

      await api
        .post("/login", payload, { withCredentials: true })
        .then((resp) => {
          this.currentUser = JSON.parse(
            JSON.stringify(resp.data.data.attributes)
          );
          localStorage.setItem("auth-token", resp.headers.authorization);
          api.defaults.headers.common["Authorization"] =
            resp.headers.authorization;
        })
        .catch((err) => {
          toast.error(err.response.data.errors || err.response.data.error);
        });
    },

    async logout() {
      await api.delete("/logout", { withCredentials: true }).then(() => {
        localStorage.removeItem("auth_token");
        api.defaults.headers.common["Authorization"] = null;
        window.location.replace("/");
      });
    },

    async sendNewPassword(email) {
      await api
        .post("/reset_password", { email: email })
        .then(() => {
          toast.success(
            "Письмо с новым паролем было успешно отправлено вам на почту"
          );
          this.closeResetPasswordDialog();
        })
        .catch((err) => {
          toast.error(err.response.data.errors || err.response.data.error);
        });
    },

    openResetPasswordDialog() {
      this.passwordDialogOpen = true;
    },

    closeResetPasswordDialog() {
      this.passwordDialogOpen = false;
    },
  },
});
